import { Grid, TextField, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import React from 'react';
import { useEffect } from 'react';

import { usePostalCodeFields } from './hooks/usePostalCodeFields';
import { useStyles } from './styles';

const PostalCodeFields = ({ data, setData, billing, handleChange, zipHelper, zipError, cityHelper, cityError }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { calcAddress, calcPostalCode } = usePostalCodeFields();

  useEffect(() => {
    if (billing) {
      setData({ ...data, billingZipCode: data?.billingZipCode });
      if (data?.billingZipCode?.length > 0) {
        let suggestion = calcAddress(data?.billingZipCode, data?.billingCity);
        if (suggestion === data?.billingZipCode) return;
        if (typeof suggestion === 'undefined') {
          setData({ ...data, billingCity: '' });
          return;
        } else {
          setData({ ...data, billingCity: suggestion });
        }
      }
    } else {
      setData({ ...data, contactZipCode: data?.contactZipCode });
      if (data?.contactZipCode?.length > 0) {
        let suggestion = calcAddress(data?.contactZipCode, data?.contactCity);
        if (suggestion === data?.contactZipCode) return;
        if (typeof suggestion === 'undefined') {
          setData({ ...data, contactCity: '' });
          return;
        } else {
          setData({ ...data, contactCity: suggestion });
        }
      }
    }
  }, [data?.contactZipCode, data?.billingZipCode]);

  useEffect(() => {
    if (billing) {
      setData({ ...data, billingCity: data?.billingCity });
      if (data?.billingCity?.length > 0) {
        let suggestion = calcPostalCode(data?.billingCity, data?.billingZipCode);
        if (suggestion === data?.billingCity) return;
        if (typeof suggestion === 'undefined') {
          setData({ ...data, billingZipCode: '' });
          return;
        } else {
          setData({ ...data, billingZipCode: suggestion });
        }
      }
    } else {
      setData({ ...data, contactCity: data?.contactCity });
      if (data?.contactCity?.length > 0) {
        let suggestion = calcPostalCode(data?.contactCity, data?.contactZipCode);
        if (suggestion === data?.contactCity) return;
        if (typeof suggestion === 'undefined') {
          setData({ ...data, contactZipCode: '' });
          return;
        } else {
          setData({ ...data, contactZipCode: suggestion });
        }
      }
    }
  }, [data?.contactCity, data?.billingCity]);

  return (
    <>
      <Grid item xs={12} md={6} className={classes.container}>
        <Typography variant='h4' className={classes.label}>
          Irányítószám
        </Typography>
        <TextField
          variant='standard'
          className={classes.input}
          id='zip'
          name='zip'
          required
          placeholder='0000'
          value={billing ? data?.billingZipCode : data?.contactZipCode}
          key='postal_code_field'
          onChange={(e) => {
            handleChange(e);
            if (billing) {
              setData({ ...data, billingZipCode: e.target.value });
            } else {
              setData({ ...data, contactZipCode: e.target.value });
            }
          }}
          error={zipHelper && Boolean(zipError)}
          helperText={zipHelper && zipError}
        />
      </Grid>
      <Grid item xs={12} md={6} className={classes.container}>
        <Typography variant='h4' className={classes.label}>
          Település
        </Typography>
        <TextField
          id='city'
          required
          name='city'
          value={billing ? data?.billingCity : data?.contactCity}
          className={classes.input}
          key='address_field'
          onChange={(e) => {
            handleChange(e);
            if (billing) {
              setData({ ...data, billingCity: e.target.value });
            } else {
              setData({ ...data, contactCity: e.target.value });
            }
          }}
          error={cityHelper && Boolean(cityError)}
          helperText={cityHelper && cityError}
        />

        {/* <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={calcAddressSuggestions(data?.contactCity)}
          inputValue={data?.contactCity}
          onChange={(e) =>
            setData({
              ...data,
              contactCity: e.target.innerHTML.includes("<")
                ? ""
                : e.target.innerHTML,
            })
          }
          renderInput={(params) => (
            <TextField
              id="city"
              required
              name="city"
              value={data?.contactCity}
              className={classes.input}
              {...params}
              key="address_field"
              onChange={(e) => {
                handleChange(e);
                setData({ ...data, contactCity: e.target.value });
              }}
              error={cityHelper && Boolean(cityError)}
              helperText={cityHelper && cityError}
            />
          )}
        /> */}
      </Grid>
    </>
  );
};

export default PostalCodeFields;

/** @format */

import {
    Card,
    CardActionArea,
    Typography,
    Zoom,
    useTheme,
    Paper,
    DialogContent,
    DialogContentText,
    Button,
    Dialog,
    Checkbox,
    FormControlLabel,
} from "@material-ui/core";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LoadIndicator from "components/common/LoadIndicator/LoadIndicator";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
    ButtonsWrapper,
    DialogContentWrapper,
    Title,
    Title2,
    Transition,
    useStyles,
} from "./styles";
import { useDocumentApi } from "pages/PhotoAdmin/Settings/Documents/hooks/useDocumentApi";
import { useSelector } from "react-redux";

const AddShopCard = ({ user, createShop, shopLoading }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const navigate = useNavigate();

    const userId = useSelector(
        (state) => state.nonPersistedReducers?.userSliceReducer?.currentUser?.id
    );

    const [open, setOpen] = useState(false);
    const [isDemo, setIsDemo] = useState(false);
    const [isAccepted, setIsAccepted] = useState();
    const { getDocuments, documents, downloadDocument } = useDocumentApi();

    const handleShopCreate = () => {
        const body = {
            name: `${user?.username}_shop`,
            aboutMeDescription: "About me!",
            footerDescription: "Footer me!",
            owner: { username: user?.username },
            isDemo: isDemo,
        };
        createShop({ body: body });
    };

    const handleOpen = () => {
        setOpen(!open);
    };

    useEffect(() => {
        if (userId) {
            getDocuments();
        }
    }, [userId]);

    const buttonRef = useRef(null);

    return (
        <>
            <a ref={buttonRef} />
            <Zoom in={true} timeout={1000}>
                <Card className={classes.cardStyle} component={Paper}>
                    <CardActionArea
                        className={classes.cardActionArea}
                        onClick={                            
                            !user?.billingInfo?.bankAccountNumber ||
                            !user?.billingInfo?.companyName ||
                            !user?.billingInfo?.vatNumber ||
                            !user?.billingInfo?.billingAddress?.country ||
                            !user?.billingInfo?.billingAddress?.city
                                ? () => {
                                      navigate("/settings/profile");
                                  }
                                : handleOpen
                        }
                        disabled={shopLoading}
                    >
                        {
                            //TODO Real billing first and lastname will be needed instead of companyName
                            !user?.billingInfo?.companyName ||
                            !user?.billingInfo?.vatNumber ||
                            !user?.billingInfo?.bankAccountNumber ||
                            !user?.billingInfo?.billingAddress?.country ||
                            !user?.billingInfo?.billingAddress?.city ? (
                                <>
                                    <ManageAccountsIcon
                                        style={{
                                            color: theme.palette.primary
                                                .backgroundSecondary,
                                            fontSize: "5rem",
                                            margin: "-6rem  0 -2rem 0",
                                        }}
                                    />
                                    <Typography className={classes.cardTitle}>
                                        Boltot csak azután tud létrehozni hogy
                                        megadta a számlázási adatait! Ezt a
                                        "Profil beállítások" oldalon teheti meg.
                                    </Typography>
                                </>
                            ) : shopLoading ? (
                                <LoadIndicator />
                            ) : (
                                <>
                                    <AddCircleIcon
                                        style={{
                                            color: theme.palette.primary
                                                .backgroundSecondary,
                                            fontSize: "5rem",
                                        }}
                                    />
                                    <Typography className={classes.cardTitle}>
                                        Bolt létrehozása
                                    </Typography>
                                </>
                            )
                        }
                    </CardActionArea>
                </Card>
            </Zoom>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                className={classes.dialog}
                onClick={(e) => e.stopPropagation()}
                onClose={() => {}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContentWrapper onClick={(e) => e.stopPropagation()}>
                    <DialogContent className={classes.dialogContent}>
                        <DialogContentText className={classes.dialogContext}>
                            <Title>Biztosan boltot szeretnél létrehozni?</Title>
                            <Title2>
                                Felhívjuk figyelmét hogy saját bolt
                                létrehozásához el kell fogadnia az alábbi
                                dokumentumokat, melyeket az Ön adataival
                                kitöltve továbbítani fogunk az Ön által megadott
                                email címre!
                            </Title2>
                        </DialogContentText>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    required
                                    onChange={(e) =>
                                        setIsAccepted(e.target.checked)
                                    }
                                />
                            }
                            label={
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        color: isAccepted
                                            ? "inherit"
                                            : "rgba(255, 0, 0, 0.88)",
                                    }}
                                >
                                    <p style={{ margin: 0 }}>
                                        Az alábbi
                                        <br />
                                        {documents &&
                                            documents?.map((d) => (
                                                <>
                                                    <a
                                                        href="/"
                                                        className={
                                                            classes.downloadLink
                                                        }
                                                        key={`downloadable_document_${d?.id}`}
                                                        target={"_blank"}
                                                        rel="noreferrer"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            downloadDocument(
                                                                d?.id,
                                                                buttonRef,
                                                                d?.name
                                                            );
                                                        }}
                                                    >
                                                        {d?.name}
                                                    </a>{" "}
                                                    <br />{" "}
                                                </>
                                            ))}{" "}
                                        dokumentumokban foglaltakat elfogadom!
                                    </p>
                                </div>
                            }
                        />
                        <DialogContentText className={classes.dialogContext}>
                            <Title2>
                                <span
                                    style={{ color: "rgba(255, 0, 0, 0.88)" }}
                                >
                                    FIGYELEM!
                                </span>{" "}
                                <br />
                                Lehetősége van Demo bolt létrehozására is, ahol
                                tesztelési céllal létrehozhat galériákat,
                                képeket tölthet fel és megismerheti az oldal
                                működését, megtapasztalhatja a digitális iskolai
                                fotózás nyújtotta lehetőségeket!
                                <br /> Demo boltját bármikor éles üzembe
                                állíthatja!
                            </Title2>
                        </DialogContentText>
                        <FormControlLabel
                        style={{marginBottom: '1rem'}}
                            control={
                                <Checkbox
                                    color="primary"
                                    required
                                    onChange={(e) => setIsDemo(!isDemo)}
                                />
                            }
                            label={
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        color: "inherit",
                                    }}
                                >
                                    <p style={{ margin: 0 }}>
                                        Demo boltot szeretnék
                                    </p>
                                </div>
                            }
                        />
                        <ButtonsWrapper>
                            <Button
                                disabled={!isAccepted}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    handleShopCreate();
                                    handleOpen();
                                }}
                            >
                                Bolt létrehozása
                            </Button>
                            <Button variant="contained" onClick={handleOpen}>
                                Bezárás
                            </Button>
                        </ButtonsWrapper>
                    </DialogContent>
                </DialogContentWrapper>
            </Dialog>
        </>
    );
};

export default AddShopCard;

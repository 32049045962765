import { getApi } from 'api/axios';
import axios from 'axios';
import { useRef } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addBatchResults,
  clearUploads,
  decrementErrorCount,
  incrementCompletedUploads,
  incrementErrorCount,
  setFileCount,
  setUploadsLoading,
  updateBatchResults,
  updateResult
} from 'redux/slices/UploadSlice';
import AuthService from 'services/AuthService';

export const useUploaderBl = () => {
  const shopId = useSelector((state) => state.nonPersistedReducers.shopSliceReducer.shopId);

  const dispatch = useDispatch();

  const [batchSize, setbatchSize] = useState(10);

  const batchSizeRef = useRef(null);
  const submitbatchSize = () => {
    if (batchSizeRef.current) {
      let value = parseInt(batchSizeRef.current.value);
      if (isNaN(value)) {
        alert('Batch count must be a number.');
      } else {
        setbatchSize(value);
      }
    }
  };

  const fileInputRef = useRef(null);

  const handleInputClick = () => {
    fileInputRef.current.click();
  };

  const getFiles = (eventFiles) => {
    let files = [];
    for (let i = 0; i < eventFiles.length; i++) {
      files.push(eventFiles.item(i));
    }

    dispatch(setFileCount(files.length));

    return files;
  };

  const getPromiseBatches = (files, groupId, groupModifiers) => {
    const url = `/admin/shops/${shopId}/groups/${groupId}/products`;
    const batchCount = Math.ceil(files.length / batchSize);
    let batches = [];
    // init batches with initialBatchState
    for (let i = 0; i < batchCount; i++) {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      batches.push({ done: false, promises: [], doneCount: 0, cancelToken: source });
    }
    files.forEach((file, idx) => {
      const currentBatch = Math.floor(idx / batchSize);

      const form = new FormData();
      const productDto = {
        name: file.name,
        groupId: groupId,
        isActive: true,
        modifiers: [],
        inheritModifiers: true
      };

      form.append('imageFile', file);
      form.append(
        'productDTO',
        new Blob([JSON.stringify(productDto)], {
          type: 'application/json'
        })
      );
      let promiseConfig = {
        url,
        form,
        name: file.name,
        config: {
          headers: {
            Authorization: AuthService.authHeader(),
            'Content-Type': 'multipart/form-data'
          },
          cancelToken: batches[currentBatch].cancelToken.token
        }
      };
      batches[currentBatch].promises.push(promiseConfig);
    });

    return batches;
  };

  const isOk = (status) => {
    if (status < 300 && status >= 200) return 'true';
    return 'false';
  };

  const processBatch = async (batch) => {
    const api = await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL });
    let currentPromises = [];
    let currentUploadResults = [];

    for (let i = 0; i < batch.promises.length; i++) {
      currentUploadResults.push({
        promiseConfig: {
          name: batch.promises[i].name,
          url: batch.promises[i].url,
          form: batch.promises[i].form,
          config: batch.promises[i].config
        },
        success: 'loading'
      });

      currentPromises.push(
        api
          .post(batch.promises[i].url, batch.promises[i].form, batch.promises[i].config)
          .then((result) => {
            dispatch(
              updateResult({
                promiseConfig: {
                  name: batch.promises[i].name,
                  url: batch.promises[i].url,
                  form: batch.promises[i].form,
                  config: batch.promises[i].config
                },
                success: isOk(result.status)
              })
            );
            dispatch(incrementCompletedUploads());
          })
          .catch((error) => {
            dispatch(
              updateResult({
                promiseConfig: {
                  name: batch.promises[i].name,
                  url: batch.promises[i].url,
                  form: batch.promises[i].form,
                  config: batch.promises[i].config
                },
                success: false
              })
            );
            dispatch(incrementErrorCount());
          })
      );
    }
    dispatch(addBatchResults(currentUploadResults));
    setTimeout(() => {
      batch.cancelToken.cancel('Request timed out after 20s.');
    }, 20000);
    try {
      await Promise.allSettled([...currentPromises]);
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(updateBatchResults(currentUploadResults));
    }
  };

  const processBatches = async (batches, refresh) => {
    if (batches.length === 0) {
      dispatch(setUploadsLoading(false));
      await refresh();
      return;
    }

    await processBatch(batches.pop());
    processBatches(batches, refresh);
  };

  const handleFileUpload = async (event, groupId, groupModifiers, refresh) => {
    dispatch(setUploadsLoading(true));
    dispatch(clearUploads());
    const eventFiles = event.target.files;
    const files = getFiles(eventFiles);
    const promiseBatches = getPromiseBatches(files, groupId, groupModifiers);

    processBatches(promiseBatches, refresh);

    event.target.value = '';
  };

  const handleRetry = async ({ promiseConfig }) => {
    dispatch(
      updateResult({
        promiseConfig: promiseConfig,
        success: 'loading'
      })
    );
    dispatch(setUploadsLoading(true));
    try {
      const api = await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL });
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      setTimeout(() => {
        if (!req) {
          source.cancel('Timed out by batch process.');
        }
      }, parseInt(window._env_.REACT_APP_AXIOS_TIMEOUT) || 22000);

      const req = await api
        .post(promiseConfig.url, promiseConfig.form, { ...promiseConfig.config, cancelToken: source.token })
        .then((response) => {
          dispatch(
            updateResult({
              promiseConfig: promiseConfig,
              success: isOk(response.status)
            })
          );
          dispatch(incrementCompletedUploads());
          dispatch(decrementErrorCount());
        })
        .catch((error) => {
          console.log(error);
          if (error?.response?.data?.message?.split(' ')[0] == '101') {
            dispatch(
              updateResult({
                promiseConfig: promiseConfig,
                success: 'true'
              })
            );
            dispatch(incrementCompletedUploads());
            dispatch(decrementErrorCount());
          } else {
            dispatch(
              updateResult({
                promiseConfig: promiseConfig,
                success: 'false'
              })
            );
          }
        });
    } catch (e) {
      dispatch(
        updateResult({
          promiseConfig: promiseConfig,
          success: 'false'
        })
      );
    }

    dispatch(setUploadsLoading(false));
  };
  const batches = useSelector((state) => state.nonPersistedReducers.uploadSliceReducer.batches);

  const handleRetryAll = async (groupId) => {
    dispatch(setUploadsLoading(true));

    batches.forEach(async (batch) => {
      batch.forEach(async ({ promiseConfig, success }) => {
        if (!success || success === 'false')
          await handleRetry({
            promiseConfig: {
              url: promiseConfig.url,
              name: promiseConfig.name,
              form: promiseConfig.form,
              config: promiseConfig.config
            },
            groupId: groupId
          });
      });
    });
    dispatch(setUploadsLoading(false));
  };

  return {
    batchSize,
    batchSizeRef,
    submitbatchSize,
    fileInputRef,
    handleInputClick,
    handleFileUpload,
    handleRetry,
    handleRetryAll
  };
};

/** @format */

import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import ChartQueryBuilder from "components/common/ChartQueryBuilder/ChartQueryBuilder";
import { useReportChartLogics } from "./useReportChartLogics";
import { MenuItem, Select } from "@mui/material";

const ReportChart = ({
    url,
    chartName,
    siteType,
    dataType,
    modifiers,
    charts,
}) => {
    const [selectedChart, setSelectedChart] = useState(charts[0].value);
    const { fetchOrderedItems, orderedData, stateB, stateC, baseState } =
        useReportChartLogics(
            url,
            selectedChart,
            chartName,
            dataType,
            modifiers
        );

    useEffect(() => {
        fetchOrderedItems();
    }, []);

    useEffect(() => {
        console.log(orderedData);
    }, [orderedData]);

    return (
        <div>
            <ChartQueryBuilder
                chartName={chartName}
                siteType={siteType}
                disabled={!orderedData}
                accountingData={orderedData}
                refetch={fetchOrderedItems}
            >
                <Select
                    id="language"
                    name="language"
                    value={selectedChart}
                    onChange={(e) => {
                        setSelectedChart(e.target.value);
                    }}
                >
                    {charts.map((c) => (
                        <MenuItem value={c.value}>{c.displayName}</MenuItem>
                    ))}
                </Select>
                <div style={{ marginTop: "1.5rem" }}>
                    <ReactApexChart
                        key={selectedChart}
                        options={
                            dataType === "ordered" &&
                                  selectedChart === "bar"
                                ? stateC?.options
                                : stateB?.options
                        }
                        series={
                             dataType === "ordered" &&
                                  selectedChart === "bar"
                                ? stateC?.series
                                : stateB?.series
                        }
                        type={selectedChart}
                        height={
                            selectedChart === "pie" || selectedChart === "donut"
                                ? 363
                                : 350
                        }
                    />
                </div>
            </ChartQueryBuilder>
        </div>
    );
};

export default ReportChart;
